@import url('https://fonts.googleapis.com/css2?family=Spicy+Rice&display=swap');

.item-section-header {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('../../images//paint.svg');
  background-repeat: no-repeat;
  background-position: left;
  position: relative;
  z-index: 100;
}

.item-section-header::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, .8); */
  z-index: -1;
}

.item-section-header-name>h1 {
  color: transparent;
  margin-bottom: 0;
  font-family: "Spicy Rice", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.5rem;
  background-image: linear-gradient(90deg, #f48a0c, #f1a61e, #edc731);
  -webkit-background-clip: text;
  background-clip: text;
  -moz-background-clip: text;
  border-image-slice: 1;
  padding-right: 0;
}

.header-sub-title {
  margin-top: -15px;
}

@media screen and (max-width:768px) {
  .item-section-header-name>h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width:600px) {
  .item-section-header-name>h1 {
    font-size: 2.8rem;
  }
}
