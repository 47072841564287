.item-list-container {
  max-width: 940px;
  margin-bottom: 5rem;
}

.disclaimer {
  text-align: center;
  font-style: italic;
  font-size: 14px;
  color: #d0a44c;
  padding: 8px;
}
.food-container{
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.food-coming-soon{
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
}

@media screen and (max-width: 500px){
  .food-coming-soon{
    font-size: 2rem;
  }
}

.food-container > h1 > span {
  color: #d0a44c;
}
