.itemSection{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 10;
  padding: 20px 15px;
}
.itemSection::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .87);
  z-index: -100;
}
@media screen and (max-width: 1000px) {
  .itemSection {
    padding: 15px 8px;
  }
}
