.item-container-wrapper{
  padding: 8px;
  border: 2px solid #d0a44c;;
}

.sub-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-top: 2px solid #d0a44c; */
}

.sub-section-title{
  text-align: center;
  margin-bottom: 0;
  padding: 6px;
  font-weight: bold;
  font-size: 1.5rem;
  border-top: 2px solid #d0a44c;
}

@media screen and (max-width: 950px) {
  .item-container-wrapper{
    padding: 0px;
  }
}
